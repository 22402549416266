import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import '../styles/./PolicyDetailsCard.scss';
import '../styles/DocumentDetailsCard.scss';
import '../styles/Global.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import '@aaa-ncnu-ie/fonts/cabin/index.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DocumentsRequest,
  DocumentsRequestNameEmail,
} from '../api/schema/apiDocuments';
import documentDetailsCardSchema from '../yup-schemas/DocumentDetailsCard';
import { useNavigate } from 'react-router-dom';
import { getCookie } from './helpers/cookies';
import policyDetails from '../helpers/policyDetails';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { PatternFormat } from 'react-number-format';
import useSegment from '../hooks/useSegment';
import { InsuredContext } from './contexts/InsuredContext';

function RealTimeDocumentDetails(this: any) {
  const navigate = useNavigate();
  const [insuredContext] = useContext(InsuredContext);
  const [name, setName] = useState('');
  const [lenderName, setLenderName] = useState('');
  const [phone, setPhone] = useState('');
  const [, setEOI] = useState(false);
  const [, setReplacement] = useState(false);
  const [, setBill] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([] as Array<string>);
  const { track } = useSegment();

  const [emailButton, setEmail] = useState(false);
  const [faxButton, setFax] = useState(false);
  const [email, setEmailInput] = useState('');
  const [fax, setFaxInput] = useState('');
  // const [documentDetails] = useState({} as DocumentsRequest);
  const [selectedOption, setSelectedOption] = useState('');
  const showEmail = selectedOption === 'email';
  const showFax = selectedOption === 'fax';

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const documents: DocumentsRequestNameEmail = JSON.parse(
      sessionStorage.getItem('Request Documents') || '{}'
    );
    if (!getCookie('token')) {
      navigate('/');
    }
    setName('');
    setLenderName(documents.lenderName || '');
    setEmailInput(documents?.email || '');
    setFaxInput('');
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(documentDetailsCardSchema),
    values: {
      name,
      lenderName,
      phone,
      showEmail,
      email,
      showFax,
      fax,
    },
  });

  const handleDocumentTypeChange = (docType: string) => {
    if (documentTypes.indexOf(docType) < 0) {
      setDocumentTypes(documentTypes.concat(docType));
    } else {
      setDocumentTypes(documentTypes.filter((doc) => docType !== doc));
    }
  };

  const segmentTrackDocumentCheckboxSelected = (
    checked: boolean,
    document: string
  ) => {
    if (checked) {
      track('Document Request Changed', {
        document,
        action: checked ? 'selected' : 'deselected',
        event_type: 'Option Selected',
      });
    }
  };

  const segmentTrackDeliveryCheckbox = (
    checked: boolean,
    deliveryMethod: string
  ) => {
    track('Delivery Method Changed', {
      delivery_method: deliveryMethod,
      action: checked ? 'selected' : 'deselected',
      event_type: 'Option Selected',
    });
  };

  const handleEOI = (checked: boolean) => {
    setEOI(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Evidence of Insurance');
    handleDocumentTypeChange('EVIDENCE_OF_INSURANCE');
  };
  const handleReplaceCost = (checked: boolean) => {
    setReplacement(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Replacement Cost Estimator');
    handleDocumentTypeChange('REPLACEMENT_COST');
  };
  const handleBill = (checked: boolean) => {
    setBill(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Bill');
    handleDocumentTypeChange('BILL');
  };
  const handleEmailOrFax = (value: any) => {
    const emailChecked = value === 'email';
    const faxChecked = value === 'fax';
    setEmail(emailChecked);
    segmentTrackDeliveryCheckbox(emailChecked, 'email');
    setFax(faxChecked);
    segmentTrackDeliveryCheckbox(faxChecked, 'fax');
  };

  const handleClick = () => {
    const docsRequest: DocumentsRequest = policyDetails.buildRealTimeDocRequest(
      name,
      insuredContext,
      emailButton,
      faxButton,
      documentTypes
    );
    sessionStorage.setItem('Documents', JSON.stringify(docsRequest));
  };

  return (
    <div className="DocumentDetailsCard">
      <div className="PolicyDetailsCard-title">Request documents</div>
      <div className="PolicyDetailsCard-main">
        <Container className="PolicyDetailsCard-details">
          <Box className="PolicyDetailsCard-box-details">
            <div className="PolicyDetailsCard-details-section">
              <div className="flexForm">
                <div className="DocumentDetailsCard-title-middle">
                  Select documents to send via email or fax
                </div>

                <FormGroup>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid black',
                      width: '820px',
                      height: '40px',
                      borderRadius: '4px',
                      paddingLeft: '20px',
                      marginBottom: '16px',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleEOI(true)}
                          sx={{
                            color: '#0B1421',
                            '&.Mui-checked': {
                              color: '#0B1421',
                            },
                          }}
                        />
                      }
                      label="  Evidence of insurance"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      border: '1px solid black',
                      width: '820px',
                      height: '40px',
                      borderRadius: '4px',
                      padding: '8px, 16px, 8px, 16px',
                      paddingLeft: '20px',
                      marginBottom: '16px',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleReplaceCost(true)}
                          sx={{
                            color: '#0B1421',
                            '&.Mui-checked': {
                              color: '#0B1421',
                            },
                          }}
                        />
                      }
                      label="Replacement Cost Estimator"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      border: '1px solid black',
                      width: '820px',
                      height: '40px',
                      borderRadius: '4px',
                      padding: '8px, 16px, 8px, 16px',
                      paddingLeft: '20px',
                      marginBottom: '16px',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleBill(true)}
                          sx={{
                            color: '#0B1421',
                            '&.Mui-checked': {
                              color: '#0B1421',
                            },
                          }}
                        />
                      }
                      label="Bill"
                    />
                  </Box>
                </FormGroup>

                <div className="DocumentDetailsCard-title-middle"></div>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    sx={{
                      color: 'black',
                      font: 'Cabin',
                      weight: '400',
                      size: '18px',
                      lineHeight: '24px',
                      paragraph: '12px',
                      letter: '0.4px',
                      marginBottom: '20px',
                    }}
                  >
                    Delivery method
                  </FormLabel>

                  <RadioGroup
                    name="use-radio-group"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="email"
                      label="Email"
                      control={
                        <Radio onClick={() => handleEmailOrFax('email')} />
                      }
                      sx={{ marginBottom: '20px' }}
                    />
                    {selectedOption === 'email' && (
                      <Box sx={{ '& fieldset': { border: 'none' } }}>
                        <TextField
                          {...register('name')}
                          error={!!errors.name}
                          label="Your name"
                          fullWidth
                          inputProps={{ disableUnderLine: true }}
                          variant="standard"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          helperText={`${errors.name?.message || ''}`}
                          sx={{ marginBottom: '10px' }}
                          required
                        />

                        <TextField
                          {...register('lenderName')}
                          error={!!errors.lenderName}
                          label="Full lender name"
                          fullWidth
                          variant="standard"
                          value={lenderName}
                          onChange={(e) => setLenderName(e.target.value)}
                          helperText={`${errors.lenderName?.message || ''}`}
                          sx={{ marginBottom: '10px' }}
                          required
                        />
                        <PatternFormat
                          customInput={TextField}
                          format={'###-###-####'}
                          mask="_"
                          onValueChange={({ value }) => {
                            setValue('phone', value);
                            setPhone(value);
                          }}
                          onBlur={() => {
                            trigger('phone');
                          }}
                          error={!!errors.phone}
                          label="Phone number"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: 'csaa-qm-encrypt',
                            },
                          }}
                          variant="standard"
                          value={phone}
                          helperText={`${errors.phone?.message || ''}`}
                          required
                        />
                        {showEmail ? (
                          <TextField
                            {...register('email')}
                            error={!!errors.email}
                            label="Email"
                            fullWidth
                            variant="standard"
                            value={email}
                            inputProps={{ readOnly: true }}
                            onChange={(e) => setEmailInput(e.target.value)}
                            helperText={`${
                              errors.email?.message ||
                              "Enter the recipient's email address."
                            }`}
                            sx={{ marginBottom: '10px' }}
                            required
                          />
                        ) : null}
                      </Box>
                    )}
                    <FormControlLabel
                      value="fax"
                      label="Fax"
                      control={
                        <Radio onClick={() => handleEmailOrFax('fax')} />
                      }
                    />
                    {selectedOption === 'fax' && (
                      <Box sx={{ mt: 1 }}>
                        <TextField
                          {...register('name')}
                          error={!!errors.name}
                          label="Your name"
                          fullWidth
                          variant="standard"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          helperText={`${errors.name?.message || ''}`}
                          sx={{ marginBottom: '10px' }}
                          required
                        />
                        <TextField
                          {...register('lenderName')}
                          error={!!errors.lenderName}
                          label="Full lender name"
                          fullWidth
                          variant="standard"
                          value={lenderName}
                          onChange={(e) => setLenderName(e.target.value)}
                          helperText={`${errors.lenderName?.message || ''}`}
                          sx={{ marginBottom: '10px' }}
                          required
                        />
                        <PatternFormat
                          customInput={TextField}
                          format={'###-###-####'}
                          mask="_"
                          onValueChange={({ value }) => {
                            setValue('phone', value);
                            setPhone(value);
                          }}
                          onBlur={() => {
                            trigger('phone');
                          }}
                          error={!!errors.phone}
                          label="Phone number"
                          fullWidth
                          InputProps={{
                            classes: {
                              input: 'csaa-qm-encrypt',
                            },
                          }}
                          variant="standard"
                          value={phone}
                          helperText={`${errors.phone?.message || ''}`}
                          sx={{ marginBottom: '10px' }}
                          required
                        />

                        {showFax ? (
                          <PatternFormat
                            customInput={TextField}
                            format={'###-###-####'}
                            mask="_"
                            InputProps={{
                              classes: {
                                input: 'csaa-qm-encrypt',
                              },
                            }}
                            onValueChange={({ value }) => {
                              setValue('fax', value);
                              setFaxInput(value);
                            }}
                            onBlur={() => {
                              trigger('fax');
                            }}
                            error={!!errors.fax}
                            label="Fax Number"
                            variant="standard"
                            fullWidth
                            value={fax}
                            helperText={`${errors.fax?.message || ''}`}
                            sx={{ marginBottom: '10px' }}
                            required
                          />
                        ) : null}
                      </Box>
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="DocumentDetailsCard-button-info"></div>
            <div className="buttonGroup">
              <Button
                variant="contained"
                onClick={handleSubmit(() => {
                  sessionStorage.setItem(
                    'ContactInfo',
                    JSON.stringify({
                      name,
                      lenderName,
                      phone,
                      email,
                      fax,
                    })
                  );
                  handleClick();
                  navigate('/review');
                })}
                disabled={!documentDetailsCardSchema.isValidSync(getValues())}
              >
                Continue
              </Button>
              <Button
                sx={{
                  color: '#0B1421',
                  borderColor: '#0B1421',
                  '&:hover': {
                    borderColor: '#0B1421',
                  },
                }}
                variant="outlined"
                onClick={() => {
                  navigate('/details');
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default RealTimeDocumentDetails;
