import * as yup from 'yup';

const otvcCardSchema = yup.object({
  code: yup
    .string()
    .required('Code is required')
    .matches(/^\d{5}$/, 'Code must have 5 characters'),
});

export default otvcCardSchema;
