import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import React from 'react';
import { Typography } from '@mui/material';
import hotAirBalloonImg from '../../images/hot-air-balloon.svg';
import cloud from '../../images/cloud-blue.svg';
import cloudLight from '../../images/cloud-light-blue.svg';
import Modal from '../common/Modal';
import '../../styles/modal/SessionInactiveModal.scss';

const SessionInactiveModal = (props: {
  open: boolean;
  onClose: () => void;
  onAffirmative: () => void;
}) => {
  const { open, onClose, onAffirmative } = props;

  return (
    <Modal
      fullWidth={false}
      maxWidth={'md'}
      title="Your session has expired."
      open={open}
      className="session-inactive-modal"
      modalName="session-inactive-modal"
      onClose={onClose}
    >
      <Container className="modal-content">
        <div className="baloon-image">
          <img src={hotAirBalloonImg} className="balloon" alt="" />
          <img src={cloud} className="top-cloud" alt="" />
          <img src={cloudLight} className="bottom-cloud" alt="" />
        </div>
        <Typography className="modal-content-text">
          Your session has expired due to inactivity. Please start over to
          complete your request
        </Typography>
      </Container>
      <Button
        variant="contained"
        className="start-over"
        onClick={onAffirmative}
      >
        Start Over
      </Button>
    </Modal>
  );
};

export default SessionInactiveModal;
