import Cookies, { CookieSetOptions } from 'universal-cookie';
import moment from 'moment';

const cookies = new Cookies();

export const getCookie = (name: string): string => {
  const cookie = cookies.get(name);
  const isEmpty = !cookie || ['undefined', 'null', ''].includes(cookie);
  return !isEmpty ? cookie : '';
};

const _setCookie = (
  name: string,
  value: unknown,
  options?: CookieSetOptions
) => {
  const secure = process.env.NODE_ENV === 'production';
  cookies.set(name, value, {
    path: '/',
    secure,
    httpOnly: false,
    sameSite: 'lax',
    expires: new Date(moment().add(30, 'm').format()),
    ...options,
  });
};

export const setCookie = (
  name: string,
  value: unknown,
  options?: CookieSetOptions
) => {
  value && _setCookie(name, value, options);
};

export const removeCookie = (name: string) => {
  cookies.remove(name);
};

export const clearSessionAndCookies = () => {
  sessionStorage.clear();
  cookies.remove('token');
};

export const resetCookie = (name: string) => _setCookie(name, undefined);
