import '../styles/Maintenance.scss';
import '../styles/Global.scss';
import Container from '@mui/material/Container';
import React from 'react';
import maintenanceImg from '../images/maintenance.svg';
import BasicLayout from './layouts/BasicLayout';

const MaintenanceCard = () => {
  return (
    <BasicLayout>
      <div className="container maintenance">
        <Container className="maintenance-img">
          <img src={maintenanceImg} className="image" alt="" />
        </Container>
        <Container className="inner form">
          <div className="title">Sorry, we&apos;re down for maintenance.</div>
          <div className="desc">
            We should be up and running shortly. Thanks for your patience.
          </div>
        </Container>
      </div>
    </BasicLayout>
  );
};

export default MaintenanceCard;
