import React, { createContext, useState } from 'react';

export type InsuredContextState = {
  policyNumber?: string;
  insuredLastName?: string;
  insuredDateOfBirth?: string;
  propertyZipCode?: string;
  lenderName?: string;
  lenderEmail?: string;
  lenderOther?: string;
  incorrectPolicyInformation?: boolean;
  errorRetrievingPolicy?: boolean;
};

type ContainerProps = {
  children: React.ReactNode;
};

export type UpdateInsuredContextState = React.Dispatch<
  React.SetStateAction<InsuredContextState>
>;

type InsuredContextType = [InsuredContextState, UpdateInsuredContextState];

export const InsuredContext = createContext<InsuredContextType>([
  { incorrectPolicyInformation: false, errorRetrievingPolicy: false },
  () => undefined,
]);

const InsuredContextProvider = ({ children }: ContainerProps) => {
  const [insuredState, setInsuredState] = useState<InsuredContextState>({
    incorrectPolicyInformation: false,
    errorRetrievingPolicy: false,
  });
  return (
    <InsuredContext.Provider value={[insuredState, setInsuredState]}>
      {children}
    </InsuredContext.Provider>
  );
};

export default InsuredContextProvider;
