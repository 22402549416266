import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSegment from '../hooks/useSegment';
import lenderImg from '../images/lender-landing-image.svg';
import LocationIcon from '../images/icons/location-icon.svg';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import { InsuredContext } from './contexts/InsuredContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import policyLookupSchema from '../yup-schemas/PolicyLookupCard';
import dayjs from 'dayjs';
import ReCAPTCHA from 'react-google-recaptcha';
import useRequest from '../api/makeRequest';
import { useActionCreators } from '../api/actions';
import { Alert, AlertTitle, Collapse, Typography } from '@mui/material';
import lenderNames from './data/lenderNames.json';
import { clearSessionAndCookies } from './helpers/cookies';
import { getYearFromStringDateDDmmYYYY } from './helpers/utils';

function PolicyLookup(this: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(policyLookupSchema),
  });
  const [insuredDateOfBirth, setInsuredDateOfBirth] = React.useState(null);
  const [otherText, setOtherText] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [insuredContext, setInsuredContext] = useContext(InsuredContext);
  const [recaptchaToken, setRecaptchaToken] = React.useState('');
  const [recaptchaMessage, setRecaptchaMessage] = React.useState('');
  const navigate = useNavigate();
  const isRecaptchaEnabled =
    process.env.REACT_APP_GOOGLE_RECAPTCHA_ENABLED === 'true';
  const { identify, track } = useSegment();

  const handleInput = (event: any) => {
    setInsuredContext({
      ...insuredContext,
      ...{
        [event.target.name]:
          typeof event.target.value !== 'number'
            ? event.target.value
            : event.target.innerText,
      },
    });
  };

  useEffect(() => {
    clearSessionAndCookies();
    setInsuredContext({
      incorrectPolicyInformation: insuredContext.incorrectPolicyInformation,
      errorRetrievingPolicy: insuredContext.errorRetrievingPolicy,
    });
    window.recaptchaOptions = {
      enterprise: true,
    };
  }, []);

  const identifySegmentUser = () => {
    identify(insuredContext.lenderEmail, {
      id: insuredContext.lenderEmail,
      lender_name:
        insuredContext.lenderName === 'Other - Please Specify'
          ? insuredContext.lenderOther
          : insuredContext.lenderName,
    });
  };

  const trackSegmentUser = () => {
    track('Policy Information Requested', {
      insured_last_name: insuredContext.insuredLastName,
      zip_code: insuredContext.propertyZipCode,
      insured_dob: getYearFromStringDateDDmmYYYY(
        insuredContext?.insuredDateOfBirth
      ),
      event_type: 'User Information Entered',
    });
  };

  const isOther = (value: string | null) =>
    ['Other - Please Specify', 'Lender name not found, add now?'].includes(
      value || ''
    );

  const {
    actionCreators: { validateRecaptcha },
  } = useActionCreators();
  const makeRequest = useRequest();

  return (
    <>
      <Container>
        <Collapse in={insuredContext.incorrectPolicyInformation} unmountOnExit>
          <Alert
            severity="error"
            className="PolicyLookupCard-error-message"
            onClose={() => {
              setInsuredContext((state) => ({
                ...state,
                incorrectPolicyInformation: false,
              }));
            }}
          >
            <AlertTitle>We’re unable to locate this policy.</AlertTitle>
            Please check your information and try again.
          </Alert>
        </Collapse>
      </Container>
      {insuredContext.errorRetrievingPolicy && (
        <Alert severity="error" className="PolicyLookupCard-error-message">
          <AlertTitle>We’re unable to process your request.</AlertTitle>
          Please try again later.
        </Alert>
      )}
      <Container className="container">
        <Container className="lender-img">
          <img src={lenderImg} className="image" alt="" />
        </Container>
        <Container className="inner form">
          <div className="title">
            LenderHub, the AAA Insurance Self-Service Portal
          </div>
          <div className="desc">
            LenderHub is an easy way to review policy status, current mortgagee
            & lien holder information, request mortgagee changes, and evidence
            of insurance documents.
          </div>
          <div className="form-text">
            Please enter the information below to locate a policy
          </div>
          <TextField
            {...register('policyNumber')}
            error={!!errors.policyNumber}
            label="Policy Number"
            variant="filled"
            id="policy-number"
            name="policyNumber"
            helperText={`${
              errors.policyNumber?.message || ''
            } ex. AZH3987654321`}
            fullWidth
            onChange={handleInput}
            required
            inputProps={{
              maxLength: 15,
              style: { textTransform: 'uppercase' },
            }}
            disabled={insuredContext.errorRetrievingPolicy}
          />
          <TextField
            {...register('insuredLastName')}
            error={!!errors.insuredLastName}
            label="Insured Last Name"
            variant="filled"
            id="insured-last-name"
            fullWidth
            name="insuredLastName"
            helperText={`${errors.insuredLastName?.message || ''}`}
            onChange={handleInput}
            required
            disabled={insuredContext.errorRetrievingPolicy}
          />
          <Box
            className="split"
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '50%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              {...register('propertyZipCode')}
              error={!!errors.propertyZipCode}
              variant="filled"
              className="split-item no-shadow"
              label="Property Zip Code"
              name="propertyZipCode"
              helperText={`${errors.propertyZipCode?.message || ''}`}
              onChange={handleInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={LocationIcon} alt="" />
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 5 }}
              required
              disabled={insuredContext.errorRetrievingPolicy}
            />
            <DesktopDatePicker
              className="no-shadow"
              label="Insured Date of Birth"
              inputFormat="MM/DD/YYYY"
              value={insuredDateOfBirth}
              onChange={(value) => {
                setInsuredDateOfBirth(value);
                const formattedDate = dayjs(value).format('MM/DD/YYYY');
                setValue('insuredDateOfBirth', formattedDate);
                handleInput({
                  target: {
                    name: 'insuredDateOfBirth',
                    value: formattedDate,
                  },
                });
              }}
              disableFuture
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register('insuredDateOfBirth')}
                  error={!!errors.insuredDateOfBirth}
                  InputProps={{
                    classes: {
                      input: 'csaa-qm-block',
                    },
                  }}
                  className="split-item no-shadow"
                  variant="filled"
                  name="insuredDateOfBirth"
                  helperText={`${errors.insuredDateOfBirth?.message || ''}`}
                  required
                />
              )}
              disabled={insuredContext.errorRetrievingPolicy}
            />
          </Box>
          <Autocomplete
            id="lender-id"
            options={lenderNames}
            ListboxProps={{ className: 'drop-down-item' }}
            onBlur={() => {
              trigger('lenderName');
            }}
            onChange={(event: any, newValue: string | null) => {
              handleInput({
                target: {
                  name: 'lenderName',
                  value: newValue,
                },
              });
              setValue('lenderName', newValue || '');
              if (isOther(newValue)) {
                setOtherText(true);
              } else {
                setOtherText(false);
              }
            }}
            filterOptions={(options) => {
              const filtered = options.filter((option) => {
                return option.toLowerCase().includes(inputValue.toLowerCase());
              });

              if (filtered.length === 0) {
                filtered.push('Lender name not found, add now?');
              }

              return filtered;
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors.lenderName}
                label="Lender Name"
                fullWidth
                variant="filled"
                className="no-shadow menu-select"
                helperText="Select or begin typing the name of the lender you represent"
                required
              />
            )}
            isOptionEqualToValue={(option, inputValue) =>
              [option, 'Lender name not found, add now?'].includes(inputValue)
            }
            disabled={insuredContext.errorRetrievingPolicy}
          />
          {otherText && (
            <TextField
              {...register('lenderOther')}
              error={!!errors.lenderOther}
              label="Other Lender"
              variant="filled"
              id="other-lender"
              name="lenderOther"
              helperText={`${errors.lenderOther?.message || ''}`}
              onChange={handleInput}
              fullWidth
              required
              disabled={insuredContext.errorRetrievingPolicy}
            />
          )}
          <TextField
            {...register('lenderEmail')}
            error={!!errors.lenderEmail}
            label="Lender Email Address"
            variant="filled"
            id="lender-email"
            InputProps={{
              classes: {
                input: 'csaa-qm-encrypt',
              },
            }}
            fullWidth
            name="lenderEmail"
            helperText={`${
              errors.lenderEmail?.message || 'Your email address'
            }`}
            onChange={handleInput}
            required
            disabled={insuredContext.errorRetrievingPolicy}
          />
          {isRecaptchaEnabled && (
            <>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ''}
                onChange={(token) => {
                  setRecaptchaToken(token || '');
                  setValue('recaptchaToken', token || '');
                  trigger('recaptchaToken');
                }}
                size="normal"
              />
              <Typography variant="body2" className="recaptcha-error">{`${
                errors.recaptchaToken?.message || recaptchaMessage
              }`}</Typography>
            </>
          )}
          <Button
            variant="contained"
            onClick={handleSubmit(() => {
              identifySegmentUser();
              trackSegmentUser();
              sessionStorage.setItem(
                'PolicyLookup',
                JSON.stringify({
                  ...insuredContext,
                  incorrectPolicyInformation: false,
                  errorRetrievingPolicy: false,
                })
              );
              if (isRecaptchaEnabled) {
                makeRequest(() =>
                  validateRecaptcha({ token: recaptchaToken })
                    .then((response: any) => {
                      if (!response?.success) {
                        return Promise.reject();
                      }
                      navigate('/otvc');
                    })
                    .catch(() => {
                      setRecaptchaMessage(
                        'There was a problem validating the reCaptcha response.'
                      );
                    })
                );
              } else {
                navigate('/otvc');
              }
            })}
            disabled={
              insuredContext.errorRetrievingPolicy ||
              !policyLookupSchema.isValidSync({
                ...insuredContext,
                recaptchaToken,
              })
            }
          >
            Continue
          </Button>
        </Container>
      </Container>
    </>
  );
}

export default PolicyLookup;
