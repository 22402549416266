import dayjs from 'dayjs';
import * as yup from 'yup';

const policyLookupCardSchema = yup.object({
  policyNumber: yup
    .string()
    .required('Policy Number is required')
    .max(15, 'Policy Number must have no more than 15 characters')
    .matches(/^[a-zA-Z0-9]+$/, 'Policy Number must be alphanumeric'),
  insuredLastName: yup
    .string()
    .required('Insured Last Name is required')
    .matches(/^[a-zA-Z\s-]+$/, 'Insured Last Name must be alphabetic'),
  propertyZipCode: yup
    .string()
    .required('Property Zip Code is required')
    .matches(/^\d{5}$/, 'Property Zip Code must have only 5 numbers'),
  insuredDateOfBirth: yup
    .string()
    .required('Insured Date of Birth is required')
    .test('insuredDateOfBirth', 'Invalid date', (value) =>
      dayjs(value, 'MM/DD/YYYY', true).isValid()
    ),
  lenderName: yup
    .string()
    .required('Lender Name is required')
    .matches(/^[a-zA-Z0-9\s-&.',?]+$/, 'Lender Name must be alphanumeric'),
  lenderOther: yup.string().when('lenderName', {
    is: (value: string) =>
      ['Other - Please Specify', 'Lender name not found, add now?'].includes(
        value || ''
      ),
    then: (schema: any) =>
      schema
        .required('Lender Name is required')
        .matches(/^[a-zA-Z0-9\s-&.',?]+$/, 'Lender Name must be alphanumeric'),
  }),
  lenderEmail: yup
    .string()
    .required('Lender Email is required')
    .email('Invalid email'),
  recaptchaToken: yup
    .string()
    .test(
      'recaptchaToken',
      'Please check the recaptcha box',
      (value) =>
        process.env.REACT_APP_GOOGLE_RECAPTCHA_ENABLED !== 'true' || !!value
    ),
});

export default policyLookupCardSchema;
