import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import uiKitTheme from '@aaa-ncnu-ie/ui-kit/dist/styles/defaultTheme';
import Header from './components/Header';
import Footer from './components/Footer';
import PolicyLookupCard from './components/PolicyLookupCard';
import errorIcon from './images/icons/error-icon.svg';
import closeIcon from './images/icons/close-icon.svg';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

function PolicyLookup(this: any) {
  const [showAlert, setShowAlert] = React.useState(false);
  function Alert(this: any) {
    const CloseIcon = (
      <Icon>
        <img alt="Download Document" src={closeIcon} />
      </Icon>
    );
    return (
      <div className="lender-error">
        <Box className="error-details">
          <img alt="" className="error-icon" src={errorIcon} />
          <div className="error-text">
            <p>
              <b>We&apos;re unable to locate this policy.</b>
            </p>
            <p>Please check your details and try again.</p>
          </div>
        </Box>
        <IconButton
          className="close-icon"
          onClick={() => {
            setShowAlert(false);
          }}
        >
          {CloseIcon}
        </IconButton>
      </div>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={uiKitTheme}>
        <Container maxWidth="xl" className="lender-form">
          <Header />
          {showAlert ? <Alert /> : null}
          <PolicyLookupCard />
          <Footer />
        </Container>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default PolicyLookup;
