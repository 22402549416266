import * as yup from 'yup';

const mortChangeModalSchema = yup.object({
  legalMortgageeName: yup
    .string()
    .required('Mortgagee Name is required')
    .matches(/^[a-zA-Z0-9\s-()&.',?]+$/, 'Mortgagee Name must be alphanumeric'),
  mortgageeAddress1: yup
    .string()
    .required('Mortgagee Address is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Mortgagee Address must be alphanumeric'),
  mortgageeCity: yup
    .string()
    .required('Mortgagee City is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Mortgagee City must be alphanumeric'),
  mortgageeState: yup
    .string()
    .required('Mortgagee State is required')
    .matches(
      /^[A-Z]{2}$/,
      'Mortgagee State must contains only two uppercase letters'
    ),
  mortgageePostalCode: yup
    .string()
    .required('Mortgagee Postal Code is required')
    .matches(
      /^\d{5}$|^\d{9}$/,
      'Mortgagee Postal Code must have only 5 or 9 numbers'
    ),
  loanNumber: yup
    .string()
    .required('Loan Number is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Loan Number must be alphanumeric'),
});

export default mortChangeModalSchema;
