import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActionCreators } from '../api/actions';
import useRequest from '../api/makeRequest';
import {
  PolicyResponseDetails,
  Mortgagee as PoliciesMortgagee,
  PropertyDetails,
} from '../api/schema/apiPolicies';
import {
  DocumentsRequest,
  Mortgagee as DocMortgagee,
} from '../api/schema/apiDocuments';
import useSegment from '../hooks/useSegment';
import homeIcon from '../images/icons/home-icon.svg';
import documentIcon from '../images/icons/action-documents.svg';
import { ReactComponent as DocIcon } from '../images/icons/document-icon.svg';
import userIcon from '../images/icons/user-icon.svg';
import envelopIcon from '../images/icons/envelope.svg';
import mortgageeIcon from '../images/icons/mortgagee-icon.svg';
import viewDocs from '../images/icons/view-docs-icon.svg';
import changeDocs from '../images/icons/change-docs-icon.svg';
import addMortgageeIcon from '../images/icons/add-mort.svg';
import '../styles/./PolicyDetailsCard.scss';
import '../styles/./Global.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import '@aaa-ncnu-ie/fonts/cabin/index.css';
import {
  Alert,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import policyDetails from '../helpers/policyDetails';
import { InsuredContext } from './contexts/InsuredContext';
import { LoadingContext } from './contexts/LoadingContext';
import { getCookie, setCookie } from './helpers/cookies';
import { getYearFromStringDateDDmmYYYY } from './helpers/utils';
import MortChangeModal from './modal/MortChangeModal';
import Modal from './common/Modal';
import { ReactComponent as IconInfo } from '../images/icons/info.svg';
import { ReactComponent as IconInfoYellow } from '../images/icons/info-yellow.svg';
import { ReactComponent as IconDocumentRequestInfo } from '../images/icons/document-request-info.svg';
import CheckboxChip from './common/CheckboxChip';

function PolicyDetails(this: any) {
  const [insuredContext, setInsuredContext] = useContext(InsuredContext);
  const [lienList, setLienList] = useState([] as Array<DocMortgagee>);
  const [currentLien, setCurrentLien] = useState({} as DocMortgagee);
  const [showEdit, setShowEdit] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState(false);
  const [guestUserClass, setGuestUserClass] = useState('');
  const [showButtons, setShowButtons] = useState(true);
  const [showDocs, setShowDocs] = useState(false);
  const [lienModalOpened, setLienModalOpened] = useState(false);
  const [deleteConfirmationModalOpened, setDeleteConfirmationModalOpened] =
    useState(false);
  const [, updateLoadingContext] = useContext(LoadingContext);
  const [eoi, setEOI] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([] as Array<string>);
  const [replacement, setReplacement] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [renewalInvoice, setRenewalInvoice] = useState(false);
  const [email, setEmail] = useState(false);
  const [fax, setFax] = useState(false);
  const [showRenewalCheckbox, setShowRenewalCheckbox] = useState(false);
  const [showInfoMessageNoMortgagee, setShowInfoMessageNoMortgagee] =
    useState(false);
  const [showDocumentDownloads, setShowDocumentDownloads] = useState(false);
  const isRealTimeEnabled = process.env.REACT_APP_ENABLE_MORTGAGEE === 'false';
  const [showDocumentDelivery, setShowDocumentDelivery] = useState(true);
  const makeRequest = useRequest();
  const navigate = useNavigate();
  const { track } = useSegment();
  const [segmentUserDetails, setSegmentUserDetails] = useState({});

  const {
    actionCreators: { policy },
  } = useActionCreators();
  const [policyResponse, setPolicyResponse] = useState<
    PolicyResponseDetails | undefined
  >();

  const checkForRenewalAndBuildLienList = (
    policyResponseDetails: PolicyResponseDetails | undefined
  ) => {
    if (policyResponseDetails === undefined) return;
    const {
      propertyDetails: { mortgagee },
      policySummary: { expirationDate },
    } = policyResponseDetails;
    const dateDifference = dayjs(expirationDate).diff(dayjs(), 'day');
    setShowRenewalCheckbox(dateDifference < 31 && dateDifference > -1);

    setLienList(
      mortgagee.map((mortgagee: PoliciesMortgagee) =>
        policyDetails.mapToLien(mortgagee)
      )
    );

    setShowInfoMessageNoMortgagee(!mortgagee?.length);
  };

  const segmentTrackLienToBeDeleted = (lienPosition: number) => {
    const lienToBeDeleted = lienList.find(
      (l) => l.lienPosition === lienPosition
    );
    track('Mortgagee Information Deleted', {
      event_type: 'Link Accessed',
      mortgagee_name: lienToBeDeleted?.legalMortgageeName,
      address: lienToBeDeleted?.mortgageeAddress1,
      city: lienToBeDeleted?.mortgageeCity,
      state: lienToBeDeleted?.mortgageeState,
      zip_code: lienToBeDeleted?.mortgageePostalCode,
      loan_number: lienToBeDeleted?.loanNumber,
    });
  };

  const onLienDelete = (lienPosition: number) => {
    const {
      propertyDetails: { mortgagee },
    } = policyResponse as PolicyResponseDetails;
    const originalLienSize = mortgagee.length || 0;
    let filteredLienList: Array<DocMortgagee>;

    segmentTrackLienToBeDeleted(lienPosition);

    if (lienPosition > originalLienSize) {
      filteredLienList = policyDetails.deleteRecentlyAddedLien(
        lienList,
        lienPosition
      );
    } else {
      if (lienList.length > originalLienSize) {
        filteredLienList =
          policyDetails.replaceExistingLienWithNewlyAddedOneAndMarkItAsEdit(
            lienList,
            lienPosition
          );
      } else {
        filteredLienList = policyDetails.markExistingLienToBeDeleted(
          mortgagee,
          lienList,
          lienPosition
        );
      }
    }

    setLienList(
      policyDetails.repositionLiensInAscendingOrder(filteredLienList)
    );
    setLienModalOpened(false);
    setDeleteConfirmationModalOpened(false);
  };

  const openEditLienModal = (lien: any) => {
    setCurrentLien(lien);
    setLienModalOpened(true);
  };

  const openDeleteConfirmationModal = (lien: any) => {
    setCurrentLien(lien);
    setDeleteConfirmationModalOpened(true);
  };

  const handleDocumentTypeChange = (docType: string) => {
    if (documentTypes.indexOf(docType) < 0) {
      setDocumentTypes(documentTypes.concat(docType));
    } else {
      setDocumentTypes(documentTypes.filter((doc) => docType !== doc));
    }
  };

  const segmentTrackDocumentCheckboxSelected = (
    checked: boolean,
    document: string
  ) => {
    if (checked) {
      track('Document Request Changed', {
        document,
        action: checked ? 'selected' : 'deselected',
        event_type: 'Option Selected',
      });
    }
  };

  const segmentTrackDeliveryCheckbox = (
    checked: boolean,
    deliveryMethod: string
  ) => {
    track('Delivery Method Changed', {
      delivery_method: deliveryMethod,
      action: checked ? 'selected' : 'deselected',
      event_type: 'Option Selected',
    });
  };

  const handleEOI = (checked: boolean) => {
    setEOI(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Evidence of Insurance');
    handleDocumentTypeChange('EVIDENCE_OF_INSURANCE');
  };
  const handleReplaceCost = (checked: boolean) => {
    setReplacement(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Replacement Cost Estimator');
    handleDocumentTypeChange('REPLACEMENT_COST');
  };
  const handleInvoice = (checked: boolean) => {
    setInvoice(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Invoice');
    handleDocumentTypeChange('INVOICE_PROPERTY');
  };
  const handleRenewalInvoice = (checked: boolean) => {
    setRenewalInvoice(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Renewal EOI+Invoice');
    handleDocumentTypeChange('RENEWAL_BILL');
  };
  const handleEmailOrFax = (event: React.MouseEvent, value: any) => {
    const emailChecked = value === 'email';
    const faxChecked = value === 'fax';
    setEmail(emailChecked);
    segmentTrackDeliveryCheckbox(emailChecked, 'email');
    setFax(faxChecked);
    segmentTrackDeliveryCheckbox(faxChecked, 'fax');
  };

  const handleContinue = () => {
    const docsRequest: DocumentsRequest = policyDetails.buildDocRequest(
      policyResponse,
      insuredContext,
      email,
      fax,
      documentTypes,
      lienList.filter((lien) => lien.requestType),
      isGuestUser
    );
    if (
      docsRequest.mortgageeChanges &&
      docsRequest.mortgageeChanges.length > 0
    ) {
      track('Mortgagee Changes Completed', {
        event_type: 'Link Accessed',
      });
    }

    sessionStorage.setItem('Documents', JSON.stringify(docsRequest));
  };

  const openAddLienModal = () => {
    setCurrentLien({} as DocMortgagee);
    setLienModalOpened(true);
  };

  const segmentTrackAbsenceOfMortgagees = (
    propertyDetails: PropertyDetails
  ) => {
    if (
      !propertyDetails?.mortgagee ||
      propertyDetails?.mortgagee.length === 0
    ) {
      track('No Mortgagee Information Found', {
        ...segmentUserDetails,
        ...{
          event_type: 'Messaged',
        },
      });
    }
  };

  const getPolicy = () => {
    setSegmentUserDetails({
      insured_last_name: insuredContext.insuredLastName,
      zip_code: insuredContext.propertyZipCode,
      event_type: 'Automated System Process',
      insured_dob: getYearFromStringDateDDmmYYYY(
        insuredContext?.insuredDateOfBirth
      ),
    });

    makeRequest(() =>
      policy()
        .then((response: any) => {
          setPolicyResponse(response.policyResponseDetails);

          segmentTrackAbsenceOfMortgagees(
            response?.policyResponseDetails?.propertyDetails
          );

          let isUnvalidatedUser = false;

          if (!response.policyResponseDetails?.policySummary?.policyStatus) {
            isUnvalidatedUser = true;
            setIsGuestUser(isUnvalidatedUser);
            setGuestUserClass('hidden');
            setShowEdit(true);
            setShowDocumentDelivery(true);
            setShowDocs(true);
            setShowButtons(false);
            track('Unvalidated User Admitted', {
              event_type: 'Automated System Process',
            });
          }

          if (
            response.policyResponseDetails?.documents &&
            response.policyResponseDetails.documents.length > 0
          ) {
            setShowDocumentDownloads(true);
            if (!isUnvalidatedUser) {
              setShowDocumentDelivery(false);
            }
          }
          checkForRenewalAndBuildLienList(response.policyResponseDetails);
          updateLoadingContext({ value: false });
          setCookie('token', response.accessToken);
        })
        .catch(() => {
          updateLoadingContext({ value: false });
          setInsuredContext({ incorrectPolicyInformation: true });
          navigate('/');
        })
    );
  };

  const policyIsCancelledOrInactive = [
    'Canceled',
    'Cancelled',
    'Inactive',
  ].includes(policyResponse?.policySummary?.policyStatus || '');

  useEffect(() => {
    const insured = JSON.parse(sessionStorage.getItem('PolicyLookup') || '{}');
    if (getCookie('token') && Object.keys(insured).length > 0) {
      setInsuredContext(insured);
      updateLoadingContext({
        value: true,
        message: 'We’re gathering details of the policy you’ve requested.',
      });
      getPolicy();
    } else {
      navigate('/');
    }
  }, []);

  const MortCard = ({ lien }: any) => {
    const lienText: string = policyDetails.getLienNumberText(lien.lienPosition);
    const MortName = lien?.legalMortgageeName;
    const MortName2 = '';
    const MortAddress = lien?.mortgageeAddress1;
    const MortCity = lien?.mortgageeCity;
    const MortState = lien?.mortgageeState;
    const MortZip = lien?.mortgageePostalCode;
    const MortLoan = lien?.loanNumber
      ? `Loan number:\n${lien?.loanNumber}`
      : '';

    return (
      <div className="mort-card">
        <div className="lien">
          <span>{lienText}</span>
        </div>
        <div className="lien-holder">{MortName}</div>
        <div className="lien-address" style={{ whiteSpace: 'pre-line' }}>
          {MortName2}
          <br />
          {MortAddress}
          <br />
          {MortCity}, {MortState} {MortZip}
          <br />
          <br />
          {MortLoan}
        </div>
        {showEdit && (
          <div className="buttonContainer">
            <EditButton lien={lien} />
            {lien.lienPosition > 1 && <DeleteButton lien={lien} />}
          </div>
        )}
      </div>
    );
  };

  const addMortIcon = (
    <Icon>
      <img alt="Add Mortgagee" src={addMortgageeIcon} />
    </Icon>
  );

  const DeleteConfirmationModal = ({ open, onClose }: any) => (
    <Modal
      fullWidth={false}
      maxWidth={'md'}
      title="Delete mortgagee?"
      open={open}
      className="mort-modal deleteConfirmationModal"
      modalName="mort-modal-delete"
      onClose={onClose}
    >
      <DialogContent className="PolicyDetailsCard-delete-mortgagee-message">
        <DialogContentText>
          By clicking continue, this mortgagee will be removed from this policy.
          <span />
          You will need to click “Add Mortgagee” and input the mortgagee
          information if you made a mistake.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="PolicyDetailsCard-delete-mortgagee-buttons">
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() =>
            currentLien.lienPosition && onLienDelete(currentLien.lienPosition)
          }
        >
          Continue
        </Button>
      </DialogActions>
    </Modal>
  );

  const handleLenderButtonClick = (isEdit: boolean) => {
    let selection = 'view current policy';
    if (isEdit) {
      selection = 'make mortgagee changes';
      track('', {});
      setShowEdit(true);
      setShowDocumentDelivery(true);
      setShowInfoMessageNoMortgagee(false);
    }
    track('Request Type Selected', {
      selection,
      event_type: 'Option Selected',
    });
    setShowDocs(true);
    setShowButtons(false);
  };

  const LenderButton = (props: any) => (
    <Button
      onClick={() => handleLenderButtonClick(props.edit === 'true')}
      className="lien-action"
      variant="outlined"
    >
      {props.children}
    </Button>
  );

  const mapDocumentCodeToLabel = (code: string) => {
    switch (code) {
      case 'EOI':
        return 'Evidence of insurance';
      case 'REPL_COST':
        return 'Replacement Cost Estimator';
      case 'INVOICE_PROP':
        return 'Billing Invoice';
      default:
        return 'unknown';
    }
  };

  const viewFile = async (content: string, document: string) => {
    track('Document Selected', {
      event_type: 'Link Accessed',
      document,
    });
    const binary = atob(content?.replace(/\s/g, ''));
    const len = binary?.length || 0;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary?.charCodeAt(i);
    }
    const file = new Blob([view], { type: 'application/pdf' });
    const fileUrl = window.URL.createObjectURL(file);
    window.open(fileUrl, '_blank');
  };

  const DocumentRequest = () => (
    <div className="PolicyDetailsCard-documents-section">
      {!showEdit && (
        <div className="PolicyDetailsCard-details-section">
          <img
            src={documentIcon}
            className="PolicyDetailsCard-policy-image"
            alt=""
          />
          <div style={{ width: '100%' }}>
            <div className="title">Policy Documents</div>
            {showDocumentDownloads && (
              <div>
                <div className="policy-details-card-download-available-doc">
                  The following documents are available to view and download.
                </div>
                <div className="PolicyDetailsCard-checkbox-group">
                  {policyResponse?.documents &&
                    policyResponse.documents?.map((document, index) => {
                      const category = mapDocumentCodeToLabel(
                        document.category
                      );
                      if (category === 'unknown') return '';
                      return (
                        <Chip
                          key={document.category}
                          icon={<DocIcon />}
                          label={category}
                          onClick={() => {
                            viewFile(document.content, category);
                          }}
                          style={{
                            boxShadow: `inset 0px 0px 0px 1px rgb(0 0 0 / 1)`,
                          }}
                          variant="outlined"
                        />
                      );
                    })}
                </div>
                {!showDocumentDelivery && (
                  <div className="policy-details-card-request-email-fax">
                    <button
                      onClick={() => {
                        setShowDocumentDelivery(true);
                      }}
                    >
                      Request documents via email or fax
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {showDocumentDelivery && (
        <div className="PolicyDetailsCard-details-section">
          <img
            src={envelopIcon}
            className="PolicyDetailsCard-policy-image"
            alt=""
          />
          <div style={{ width: '100%' }}>
            <div className="title">
              {'Send documents'}
              <Tooltip
                title="Evidence of Insurance, Replacement Cost Estimator, and Invoice will be delivered in near-real time. Renewal documents will be fulfilled within two business days."
                placement="right"
              >
                <IconButton>
                  <IconDocumentRequestInfo />
                </IconButton>
              </Tooltip>
            </div>
            <div className="policy-details-card-download-available-doc">
              The following documents can be sent via email or fax.
            </div>
            {showDocumentDelivery && (
              <div className="PolicyDetailsCard-checkbox-group">
                <CheckboxChip
                  label="Evidence of Insurance"
                  checked={eoi}
                  onChange={handleEOI}
                />
                <CheckboxChip
                  label="Replacement Cost Estimator"
                  checked={replacement}
                  onChange={handleReplaceCost}
                />
                <CheckboxChip
                  label="Invoice"
                  checked={invoice}
                  onChange={handleInvoice}
                />
                {showRenewalCheckbox && (
                  <CheckboxChip
                    label="Renewal EOI+Invoice"
                    checked={renewalInvoice}
                    onChange={handleRenewalInvoice}
                  />
                )}
              </div>
            )}
            <div className="policy-details-card-download-available-doc">
              Delivery method:
            </div>
            <div className="PolicyDetailsCard-checkbox-group">
              <ToggleButtonGroup
                color="primary"
                value={email ? 'email' : fax ? 'fax' : ''}
                exclusive
                aria-label="Platform"
                onChange={handleEmailOrFax}
              >
                <ToggleButton
                  sx={{ fontWeight: email ? 'bold' : 'normal' }}
                  value="email"
                >
                  Email
                </ToggleButton>
                <ToggleButton
                  sx={{ fontWeight: fax ? 'bold' : 'normal' }}
                  value="fax"
                >
                  Fax
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      )}
      <Button
        variant="contained"
        className="right"
        sx={{
          float: 'right',
        }}
        onClick={() => {
          handleContinue();
          navigate('/documents');
        }}
        disabled={!((eoi || replacement || invoice) && email !== fax)}
      >
        Continue to request
      </Button>
    </div>
  );

  const EditButton = (props: any) => (
    <Button variant="text" onClick={() => openEditLienModal(props.lien)}>
      Make changes
    </Button>
  );

  const DeleteButton = (props: any) => (
    <Button
      variant="text"
      onClick={() => openDeleteConfirmationModal(props.lien)}
    >
      Delete
    </Button>
  );

  const AddMortgagee = () => (
    <div className="mort-card mort-card-add">
      <Button startIcon={addMortIcon} onClick={openAddLienModal} variant="text">
        Add mortgagee
      </Button>
    </div>
  );

  const LenderButtons = () => (
    <>
      <div className="lender-buttons-title">
        Please select the type of request you would like to make
      </div>
      <div className="lien-action-group">
        {!showInfoMessageNoMortgagee && (
          <LenderButton edit="false">
            <div className="lien-action-text">
              <div className="button-title">
                View Current Policy Information and Documents
              </div>
              <div className="button-text">
                Requests made here will be processed in near real-time.
              </div>
            </div>
            <img src={viewDocs} className="view-docs" alt="" />
          </LenderButton>
        )}
        <LenderButton edit="true">
          <div className="lien-action-text">
            <div className="button-title">
              Make mortgagee changes and request documents
            </div>
            <div className="button-text">
              Requests made here will be processed within two business days.
            </div>
          </div>
          <img src={changeDocs} className="view-docs" alt="" />
        </LenderButton>
      </div>
    </>
  );

  const MortCards = () => {
    return (
      <div className="cards">
        {lienList.map((mortgagee, i) => {
          return mortgagee.requestType !== 'delete' ? (
            <MortCard key={i} lien={mortgagee} />
          ) : (
            ''
          );
        })}
        {showEdit ? <AddMortgagee /> : null}
      </div>
    );
  };

  return (
    <div className="PolicyDetailsCard">
      {isRealTimeEnabled === false && (
        <>
          <div className="PolicyDetailsCard-title">Property policy</div>
          <div className="PolicyDetailsCard-subtitle">
            We found the policy below based on the information you entered.
            {showDocs && (
              <>
                {' '}
                Please select the type of document and delivery method to
                continue your request.
                <br />
                If you would like to change mortgagee information, please click
                “Make changes” or “Add mortgagee”.
              </>
            )}
          </div>
          <div className="PolicyDetailsCard-main">
            <Container className="PolicyDetailsCard-header">
              <Box className="PolicyDetailsCard-box">
                <img
                  src={homeIcon}
                  className="PolicyDetailsCard-policy-image"
                  alt=""
                />
                <div className="PolicyDetailsCard-header-text">
                  <div className="PolicyDetailsCard-policy-number">
                    {policyResponse?.policySummary?.policyNumber}
                    <span
                      className={`${policyResponse?.policySummary?.policyStatus?.toLowerCase()} ${guestUserClass}`}
                    >
                      {policyResponse?.policySummary?.policyStatus}
                    </span>
                  </div>
                  <div
                    className={`PolicyDetailsCard-policy-period ${guestUserClass}`}
                  >
                    {
                      policyResponse?.policySummary?.policyDetails
                        ?.insuredAddress.addressLine1
                    }
                    ,{' '}
                    {
                      policyResponse?.policySummary?.policyDetails
                        ?.insuredAddress.city
                    }
                    ,{' '}
                    {
                      policyResponse?.policySummary?.policyDetails
                        ?.insuredAddress.stateProvCd
                    }{' '}
                    {
                      policyResponse?.policySummary?.policyDetails
                        ?.insuredAddress.postalCode
                    }
                    <br />
                    Policy period {
                      policyResponse?.policySummary?.effectiveDate
                    }{' '}
                    - {policyResponse?.policySummary?.expirationDate}
                  </div>
                </div>
              </Box>
            </Container>
            <Container className="PolicyDetailsCard-details">
              {policyIsCancelledOrInactive && (
                <Alert
                  severity="info"
                  variant="standard"
                  icon={<IconInfo />}
                  className="PolicyDetailsCard-details-info-message"
                >
                  This policy is{' '}
                  {policyResponse?.policySummary?.policyStatus.toLowerCase()}.
                  No changes can be made at this time.
                </Alert>
              )}
              <Box className="PolicyDetailsCard-box-details">
                <div
                  className={`PolicyDetailsCard-details-section ${guestUserClass}`}
                >
                  <img
                    src={userIcon}
                    className="PolicyDetailsCard-policy-image"
                    alt=""
                  />
                  <div>
                    <div className="title">Named insured(s)</div>
                    <div className="named-insured">
                      {policyDetails.getNameInsured(
                        policyResponse?.policySummary?.insureds
                      )}
                    </div>
                  </div>
                </div>
                <div className="PolicyDetailsCard-details-section-vert">
                  <img
                    src={mortgageeIcon}
                    className="PolicyDetailsCard-policy-image"
                    alt=""
                  />
                  <div className="mort-cards-collection">
                    <div className="title">Mortgagee Information</div>
                    {showInfoMessageNoMortgagee && (
                      <Alert
                        severity="warning"
                        variant="outlined"
                        icon={<IconInfoYellow />}
                        className="PolicyDetailsCard-details-info-message-no-mortgagee"
                      >
                        No mortgagee information found.
                        {!policyIsCancelledOrInactive && (
                          <Button
                            variant="text"
                            onClick={() => {
                              handleLenderButtonClick(true);
                              openAddLienModal();
                            }}
                          >
                            {' '}
                            Add mortgagee information now.
                          </Button>
                        )}
                      </Alert>
                    )}
                    <MortCards />
                  </div>
                </div>
                {policyIsCancelledOrInactive ? (
                  <div className="PolicyDetailsCard-start-over">
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate('/');
                      }}
                    >
                      Start Over
                    </Button>
                  </div>
                ) : (
                  showButtons && <LenderButtons />
                )}
                {showDocs ? <DocumentRequest /> : null}
                <MortChangeModal
                  open={lienModalOpened}
                  currentLien={currentLien}
                  lienList={lienList}
                  onClose={() => setLienModalOpened(false)}
                  originalLienSize={
                    policyResponse?.propertyDetails?.mortgagee.length || 0
                  }
                  setLienList={setLienList}
                />
                <DeleteConfirmationModal
                  open={deleteConfirmationModalOpened}
                  onClose={() => setDeleteConfirmationModalOpened(false)}
                />
              </Box>
            </Container>
          </div>
        </>
      )}
    </div>
  );
}
export default PolicyDetails;
