import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import '../styles/./PolicyDetailsCard.scss';
import '../styles/DocumentDetailsCard.scss';
import '../styles/Global.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import '@aaa-ncnu-ie/fonts/cabin/index.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DocumentsRequest } from '../api/schema/apiDocuments';
import documentDetailsCardSchema from '../yup-schemas/DocumentDetailsCard';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { getCookie } from './helpers/cookies';
// import { useActionCreators } from '../api/actions';

function DocumentDetails(this: any) {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [lenderName, setLenderName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [fax, setFax] = useState('');
  const [documentDetails, setDocumentDetails] = useState(
    {} as DocumentsRequest
  );
  const showEmail =
    documentDetails?.documentRequest?.deliveryMethod?.email !== undefined;
  const showFax =
    documentDetails?.documentRequest?.deliveryMethod?.phone !== undefined;

  useEffect(() => {
    const documents: DocumentsRequest = JSON.parse(
      sessionStorage.getItem('Documents') || '{}'
    );
    if (!getCookie('token')) {
      navigate('/');
    }
    setName(documents.requestorName || '');
    setLenderName(documents.lenderName || '');
    setEmail(documents?.documentRequest?.deliveryMethod?.email || '');
    setFax(documents?.documentRequest?.deliveryMethod?.phone || '');
    setDocumentDetails(documents);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(documentDetailsCardSchema),
    values: {
      name,
      lenderName,
      phone,
      showEmail,
      email,
      showFax,
      fax,
    },
  });
  return (
    <div className="DocumentDetailsCard">
      <div className="PolicyDetailsCard-title">Document request</div>
      <div className="PolicyDetailsCard-main">
        <Container className="PolicyDetailsCard-details">
          <Box className="PolicyDetailsCard-box-details">
            <div className="PolicyDetailsCard-details-section">
              <div className="flexForm">
                <div className="DocumentDetailsCard-title-middle">
                  Contact information
                </div>
                <TextField
                  {...register('name')}
                  error={!!errors.name}
                  label="Your name"
                  variant="filled"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  helperText={`${errors.name?.message || ''}`}
                  required
                />
                <TextField
                  {...register('lenderName')}
                  error={!!errors.lenderName}
                  label="Full lender name"
                  variant="filled"
                  value={lenderName}
                  onChange={(e) => setLenderName(e.target.value)}
                  helperText={`${errors.lenderName?.message || ''}`}
                  required
                />
                <PatternFormat
                  customInput={TextField}
                  format={'###-###-####'}
                  mask="_"
                  onValueChange={({ value }) => {
                    setValue('phone', value);
                    setPhone(value);
                  }}
                  onBlur={() => {
                    trigger('phone');
                  }}
                  error={!!errors.phone}
                  label="Phone number"
                  InputProps={{
                    classes: {
                      input: 'csaa-qm-encrypt',
                    },
                  }}
                  variant="filled"
                  value={phone}
                  helperText={`${errors.phone?.message || ''}`}
                  required
                />
                <div className="DocumentDetailsCard-title-middle">
                  Where should we send the documents?
                </div>
                {showEmail ? (
                  <TextField
                    {...register('email')}
                    error={!!errors.email}
                    label="Email"
                    variant="filled"
                    value={email}
                    InputProps={{
                      classes: {
                        input: 'csaa-qm-encrypt',
                      },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    helperText={`${errors.email?.message || ''}`}
                    required
                  />
                ) : null}
                {showFax ? (
                  <PatternFormat
                    customInput={TextField}
                    format={'###-###-####'}
                    mask="_"
                    InputProps={{
                      classes: {
                        input: 'csaa-qm-encrypt',
                      },
                    }}
                    onValueChange={({ value }) => {
                      setValue('fax', value);
                      setFax(value);
                    }}
                    onBlur={() => {
                      trigger('fax');
                    }}
                    error={!!errors.fax}
                    label="Fax Number"
                    variant="filled"
                    value={fax}
                    helperText={`${errors.fax?.message || ''}`}
                    required
                  />
                ) : null}
              </div>
            </div>
            <div className="DocumentDetailsCard-button-info">
              Select submit request below to process your request. Your
              documents will be delivered to the methods selected shortly.
            </div>
            <div className="buttonGroup">
              <Button
                variant="contained"
                onClick={handleSubmit(() => {
                  sessionStorage.setItem(
                    'ContactInfo',
                    JSON.stringify({
                      name,
                      lenderName,
                      phone,
                      email,
                      fax,
                    })
                  );
                  navigate('/review');
                })}
                disabled={!documentDetailsCardSchema.isValidSync(getValues())}
              >
                Continue
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate('/details');
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default DocumentDetails;
