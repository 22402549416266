import React from 'react';
import logo from '../images/icons/logo.svg';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import '../styles/Header.scss';
import { clearSessionAndCookies } from './helpers/cookies';

export default function Header() {
  return (
    <>
      <Grid container spacing={2} className="Header">
        <header>
          <Link href="/" onClick={clearSessionAndCookies}>
            <img src={logo} className="csaa-logo" alt="CSAA Insurance Group" />
          </Link>
        </header>
      </Grid>
    </>
  );
}
