import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import uiKitTheme from '@aaa-ncnu-ie/ui-kit/dist/styles/defaultTheme';
import Header from './components/Header';
import Footer from './components/Footer';
import ReviewRequestCard from './components/ReviewRequestCard';

function ReviewRequest(this: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={uiKitTheme}>
        <Container
          sx={{ paddingLeft: 0, paddingRight: 0 }}
          className="lender-details"
        >
          <Header />
          <Container className="body">
            <ReviewRequestCard />
          </Container>
          <Footer />
        </Container>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
export default ReviewRequest;
