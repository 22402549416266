const useRequest = () => {
  return async function <R>(
    actionCreator: () => Promise<R>,
    cb?: (res: R) => void,
    shouldRedirectOnError = true
  ): Promise<R | undefined> {
    try {
      const res = await actionCreator();
      cb && cb(res);
      return Promise.resolve(res);
    } catch (e) {
      // const err = e as FetchError;
      // const parsedError = JSON.parse(err.message);
      // const parsedErrorCode = parsedError.errorCode;
      // const additionalInfo = parsedError?.additionalInfo;
      // const currentPath = window.location.pathname;

      return shouldRedirectOnError ? Promise.reject(e) : undefined;
    }
  };
};

export default useRequest;
