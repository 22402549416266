import React from 'react';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer>
        <Container className="links">
          <Link
            href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/misc/terms-of-use.html"
            rel="noopener"
            target="_blank"
            underline="always"
          >
            {'Terms of Use'}
          </Link>
          |
          <Link
            href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/misc/privacy-notice.html"
            rel="noopener"
            target="_blank"
            underline="always"
          >
            {'Privacy Policy'}
          </Link>
          <p>
            Insurance provided by CSAA Insurance Group.
            <br />© {currentYear} CSAA Insurance Group
          </p>
        </Container>
      </footer>
    </>
  );
}
