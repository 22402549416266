import React, { createContext, useState } from 'react';
import Loading from '../Loading';

export type LoadingContextState = {
  value: boolean;
  message?: string;
};

type ContainerProps = {
  children: React.ReactNode;
};

export type UpdateLoadingContextState = (newState: LoadingContextState) => void;

type LoadingContextType = [LoadingContextState, UpdateLoadingContextState];

export const LoadingContext = createContext<LoadingContextType>([
  { value: false },
  () => undefined,
]);

const LoadingContextProvider = ({ children }: ContainerProps) => {
  const [LoadingState, setLoadingState] = useState<LoadingContextState>({
    value: false,
    message: '',
  });
  return (
    <LoadingContext.Provider value={[LoadingState, setLoadingState]}>
      {LoadingState.value && <Loading message={LoadingState.message} />}
      <div style={{ display: `${LoadingState.value ? 'none' : ''}` }}>
        {children}
      </div>
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
