export function obfuscateEmail(email?: string) {
  if (email?.includes('@')) {
    const parts = email.split('@');
    const username = parts[0];
    const obfuscatedUsername =
      username.substring(0, Math.floor(username.length / 2)) +
      '*'.repeat(username.length - Math.floor(username.length / 2));
    const [domain, ...rest] = parts[1].split('.');
    const obfuscatedDomainName = domain.replace(/./g, '*');
    const topLevelDomain = rest.join();
    return (
      obfuscatedUsername +
      '@' +
      obfuscatedDomainName +
      (topLevelDomain ? '.' + topLevelDomain : '')
    );
  }
  return '';
}
