import React from 'react';
import InsuredContextProvider from './components/contexts/InsuredContext';
import LoadingContextProvider from './components/contexts/LoadingContext';
import IdleTimer from './components/IdleTimer';
import { Outlet } from 'react-router-dom';
import RealTimeResponseContextProvider from './components/contexts/RealTimeResponseContext';

const Root = () => {
  return (
    <LoadingContextProvider>
      <InsuredContextProvider>
        <RealTimeResponseContextProvider>
          <React.StrictMode>
            <Outlet />
          </React.StrictMode>
          <IdleTimer />
        </RealTimeResponseContextProvider>
      </InsuredContextProvider>
    </LoadingContextProvider>
  );
};

export default Root;
