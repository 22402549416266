import uiKitTheme from '@aaa-ncnu-ie/ui-kit/dist/styles/defaultTheme';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { PropsWithChildren } from 'react';
import Footer from '../Footer';
import Header from '../Header';

const BasicLayout = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={uiKitTheme}>
        <Container maxWidth="xl" className="lender-form">
          <Header />
          {children}
          <Footer />
        </Container>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default BasicLayout;
