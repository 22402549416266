import React, { createContext, useState } from 'react';

export type RealTimeResponseContextState = {
  zendeskRequest?: boolean;
  realTimeRequest?: boolean;
};

type ContainerProps = {
  children: React.ReactNode;
};

export type UpdateRealTimeResponseContext = (
  newState: RealTimeResponseContextState
) => void;

type RealTimeResponseContextType = [
  RealTimeResponseContextState,
  UpdateRealTimeResponseContext
];

export const RealTimeResponseContext =
  createContext<RealTimeResponseContextType>([
    { zendeskRequest: false, realTimeRequest: false },
    () => undefined,
  ]);

const RealTimeResponseContextProvider = ({ children }: ContainerProps) => {
  const [RealTimeResponseState, setRealTimeResponseState] =
    useState<RealTimeResponseContextState>({
      zendeskRequest: false,
      realTimeRequest: false,
    });
  return (
    <RealTimeResponseContext.Provider
      value={[RealTimeResponseState, setRealTimeResponseState]}
    >
      {children}
    </RealTimeResponseContext.Provider>
  );
};

export default RealTimeResponseContextProvider;
