import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { LinearProgress, Typography } from '@mui/material';
import '../styles/Loading.scss';
import Header from './Header';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import uiKitTheme from '@aaa-ncnu-ie/ui-kit/dist/styles/defaultTheme';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';

type LoadingTypes = {
  message?: string;
};

const Loading = ({ message }: LoadingTypes) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={uiKitTheme}>
        <Header />
        <Container maxWidth="xl" className="lender-form">
          <div className="loading">
            <div className="container">
              <Typography className="topography">{message}</Typography>
              <LinearProgress />
            </div>
          </div>
        </Container>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default Loading;
